import React from 'react';
import { Common, DesignerConstants, UC, UnoCompEvents, UnoComponent } from "../../../@uno/core";
import { BasePropViewer } from "../prop-base.comp";

@UnoComponent({
    id: 'FilterDefViewer',
    label: 'Filter Definition Viewer',
    paletteable: false,
    group: DesignerConstants.PaletteGroup.Viewer.id,
    events: [UnoCompEvents.onLoad, UnoCompEvents.onUnLoad],
    props: [],

})
export class FilterDefViewer extends BasePropViewer {

    buildValue() {
        const conditions = Common.safeParse(this.getDefaultValue());
        return (
            <>
                <UC.JSONViewer defaultValue={this.getDefaultValue()} otherProps={{ hideLabel: true }} />
                <UC.FilterResults
                    title='Result:'
                    appID={this.getAppID()}
                    conditions={conditions}
                    key={Common.getUniqueKey()}
                    otherProps={this.getOtherProps()}
                />
            </>
        );
    }
}