
import { Platform } from './@uno/react/platform';
import * as serviceWorker from './serviceWorker';
import UnoIndexModule from './uno-index.module';
import { onLCP, onFID, onCLS } from 'web-vitals';

// Setup global error handling for Unhandled Promise Rejections
/*
process.on('unhandledRejection', (reason, promise) => {
    console.error('Unhandled Rejection at:', promise, 'reason:', reason);
});

process.on('uncaughtException', (err, origin) => {
    console.error('Uncaught Exception at:', origin, 'reason:', err);
});
*/

console.log(`Loading the Uno Platform`);
/**
 * Initialize the application with UnoIndexModule as the entry point.
 */
Platform.boot(UnoIndexModule);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA

// serviceWorker.unregister();
serviceWorker.register();

// web vitals:

function sendToAnalytics(metric: any) {
    // const body = JSON.stringify(metric);
    // console.log('Web Vitals Metric: ', body);
    /*
    // Use `navigator.sendBeacon()` if available, falling back to `fetch()`.
    (navigator.sendBeacon && navigator.sendBeacon('/analytics', body)) ||
        fetch('/analytics', { body, method: 'POST', keepalive: true });

    */
}

onCLS(sendToAnalytics);
onFID(sendToAnalytics);
onLCP(sendToAnalytics);

window.addEventListener('error',
    e => {
        console.log('Window Error: ', e.message, e);

        if (e.message.startsWith('ResizeObserver')
            || e.message.startsWith('Unexpected')
        ) {

            const resizeObserverErrDiv = document.getElementById(
                'webpack-dev-server-client-overlay-div'
            );
            if (resizeObserverErrDiv) {
                resizeObserverErrDiv.setAttribute('style', 'display: none');
            }

            const resizeObserverErr = document.getElementById(
                'webpack-dev-server-client-overlay'
            );

            if (resizeObserverErr) {
                resizeObserverErr.setAttribute('style', 'display: none');
            }
        }
    }
);